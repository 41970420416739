<template>
  <v-container
    id="miniconverter"
    tag="section"
  >
  
    <base-subheading>Mini Converter Discord Bot 🤖</base-subheading>

    <p>
      The one and only discord bot that can convert your Java Minecraft skin into a Mini character!<br />
      <i>Minis optionally can wear helmets and hold some minecraft items 👀 - invite it in to find out!</i>
      
      <br/><br/>

      Mini Converter now uses slash commands 
      <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
        <span class="v-chip__content">/mini</span> 
      </span>
       instead of 
      <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
        <span class="v-chip__content">!mini</span> 
      </span> 👀: <br/>
      <!-- general section -->
      <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
        <span class="v-chip__content">/mini</span> 
      </span>
      - slash command now has auto completes to easily add your username, with optional item and helmet
      <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
        <span class="v-chip__content">/mini username:mrjoshuat</span> 
      </span><br/>

      <!-- help section -->
      <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
        <span class="v-chip__content">/mini-help</span> 
      </span>
      - will send you the help section, containing all available options and example commands!
      <br/><br/>

      <v-img
        ref="image"
        max-width="332px"
        :src="require(`@/assets/mini-settings/mini-command.png`)"
        style=""
      /><br/>
      
      <v-img
        ref="image"
        max-width="332px"
        :src="require(`@/assets/mini-settings/mini-command-autocomplete.png`)"
        style=""
      /><br/>

      <div style="background-color: rgba(203, 170, 92, 0.65); padding: 10px; border-radius: 5px;">
        For Moderators / Admins: <br/>
        <!-- moderator section -->
        Limit commands to a channel by using native Discord Integrations Settings now.<br/>
        Go to
        <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
          <span class="v-chip__content">Discord Settings Menu</span> 
        </span>
        >
        <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
          <span class="v-chip__content">Integrations</span> 
        </span>
        >
        <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
          <span class="v-chip__content">Bots and App - Mini Converter</span> 
        </span>
        >
        <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
          <span class="v-chip__content">Manage</span> 
        </span>
        >
        <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
          <span class="v-chip__content">Channels</span> 
        </span>
        - Here you can disable <span class="mx-0 mb-2 v-chip v-chip--clickable v-chip--label theme--dark v-size--small grey darken-3 white--text">
          <span class="v-chip__content">#All Channels</span> 
        </span> and set your specific bot channel
      </div>

      <br/>
      <br/>
      
      <span class="ma-0 v-chip v-chip--clickable v-chip--label theme--dark v-size--small primary __web-inspector-hide-shortcut__"
        v-on:click="invite">
        <span class="v-chip__content" style="font-size: 15px">
          <span class="mdi mdi-discord"></span>
          <span style="margin-left: 10px">Invite bot to your own Discord!</span>
        </span>
      </span>
    

    <vue-flux
      :options="vfOptions"
      :images="vfImages"
      :transitions="vfTransitions"
      :captions="vfCaptions"
      ref="slider">

      <template v-slot:preloader>
          <flux-preloader />
      </template>

      <template v-slot:caption>
          <flux-caption />
      </template>

      <template v-slot:controls>
          <flux-controls />
      </template>

      <template v-slot:pagination>
          <flux-pagination />
      </template>

      <template v-slot:index>
          <flux-index />
      </template>
    </vue-flux>
  </v-container>
</template>

<script>
  import {
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPagination,
    FluxPreloader
  } from 'vue-flux';

  export default {
    name: 'HomeMiniConverter',
    components: {
      VueFlux,
      FluxCaption,
      FluxControls,
      FluxIndex,
      FluxPagination,
      FluxPreloader,
    },
    data: () => {
      const illustrations = require.context(
        '@/assets/minis',
        true,
        /^.*\.png$/
      )
      var keys = illustrations.keys(); //.map(_ => `/assets/minis/${_}`);
      console.log(keys);
      
      return {
        vfOptions: {
          size: '474px',
          autoplay: true,
				  aspectRatio: '21:9',
          delay: 350
        },
        vfImages: [
          // require(`@/assets/minis/mrjoshuat-b90b0662-208d-43aa-fa2f-08d93be575f2.png`),
          require(`@/assets/minis/xisuma-5cb1e70a-40cb-411b-ce71-08d958505b99.png`),
          require(`@/assets/minis/bdoubleo100-e08be95c-b6a1-4b93-ddf8-08d959eef549.png`),
          require(`@/assets/minis/craftypolarbear-69806d43-fce1-4c5f-d66c-08d957755ecc.png`),
          require(`@/assets/minis/cubfan135-31ef549d-2c1f-4bb9-4898-08d95b7e528f.png`),
          require(`@/assets/minis/deadmau5-0d40d78a-76ed-4887-d676-08d957755ecc.png`),
          require(`@/assets/minis/docm77-52508005-6a52-4e80-ddf2-08d959eef549.png`),
          require(`@/assets/minis/etho-1b4b7dac-bebd-41c4-4899-08d95b7e528f.png`),
          require(`@/assets/minis/falsesymmetry-3205062f-0582-4029-489a-08d95b7e528f.png`),
          require(`@/assets/minis/fwhip-0a6ee7ef-607d-4cee-d599-08d95d819f98.png`),
          require(`@/assets/minis/geminitay-e5f31b21-f5b1-4e40-ce6f-08d958505b99.png`),
          require(`@/assets/minis/goodtimewithscar-4dfa85c4-27e9-476e-ddef-08d959eef549.png`),
          require(`@/assets/minis/grian-231073db-094a-4e5d-d664-08d957755ecc.png`),
          require(`@/assets/minis/hypnotizd-0126e9f9-1f9d-4325-489b-08d95b7e528f.png`),
          require(`@/assets/minis/ijevin-f4cd3cf9-7fd1-41ad-4894-08d95b7e528f.png`),
          require(`@/assets/minis/impulsesv-2cd44408-42a5-4cdd-ddf4-08d959eef549.png`),
          require(`@/assets/minis/iskall85-dcc5fb23-1848-407d-41d9-08d9582e9481.png`),
          require(`@/assets/minis/arinalisaeriyn-55d60a9a-a660-434a-9ba4-08d94053bf76.png`),
          require(`@/assets/minis/jaymethodstwo-349a4b03-eee0-43cc-d668-08d957755ecc.png`),
          require(`@/assets/minis/joehillssays-f92e37ee-f43d-4e73-489d-08d95b7e528f.png`),
          require(`@/assets/minis/keralis-75094129-2caf-4830-d662-08d957755ecc.png`),
          require(`@/assets/minis/kimandjax-87a9c4b1-3846-437b-d5b0-08d95d819f98.png`),
          require(`@/assets/minis/lindinger-88023a5e-593d-4dd6-f7e8-08d9575a2ac9.png`),
          require(`@/assets/minis/louuuise-0723b280-ef25-4c46-9bab-08d94053bf76.png`),
          require(`@/assets/minis/mrmattranger-7c3a7299-e017-4ac6-757d-08d957704faf.png`),
          require(`@/assets/minis/mumbo-ac6eead1-6f2e-4b3b-4893-08d95b7e528f.png`),
          require(`@/assets/minis/mumbojumbo-0375701b-7ae7-4777-dded-08d959eef549.png`),
          require(`@/assets/minis/nunwardle-2e0dc18e-fac3-40f9-c3e3-08d94b66ac0f.png`),
          require(`@/assets/minis/pearlescentmoon-cf2c312d-72ae-40cc-568a-08d969423842.png`),
          require(`@/assets/minis/picklebuzz-6ed25b9d-2f33-4e67-41e6-08d9582e9481.png`),
          require(`@/assets/minis/rendog-4160c9fe-df33-44ec-489e-08d95b7e528f.png`),
          require(`@/assets/minis/sir_hunt-7e83fe45-1831-4b86-e2db-08d957efb91b.png`),
          require(`@/assets/minis/slicedlime-4c2c2554-80b5-4e3b-36c5-08d96016209d.png`),
          require(`@/assets/minis/stressmonster101-99ab8144-4d19-42e2-489f-08d95b7e528f.png`),
          require(`@/assets/minis/tadzjaz-77f09852-a992-4e46-ce59-08d958505b99.png`),
          require(`@/assets/minis/tango-7b1662ce-6cfe-4216-ddf7-08d959eef549.png`),
          require(`@/assets/minis/tinfoilchef-d65500be-5a85-4ed5-48a0-08d95b7e528f.png`),
          require(`@/assets/minis/venestor-6f65a9fe-394c-43ec-d667-08d957755ecc.png`),
          require(`@/assets/minis/vintagebeef-9df8d4c1-6499-4ad8-48a1-08d95b7e528f.png`),
          require(`@/assets/minis/welsknight-1c826966-c965-4489-48a2-08d95b7e528f.png`),
          require(`@/assets/minis/xbcrafted-7cc4d9a8-dafc-4017-48a3-08d95b7e528f.png`),
          require(`@/assets/minis/xenolithed-ee8276d2-a5a5-4ab3-d669-08d957755ecc.png`),
          require(`@/assets/minis/zedaph-4d941aff-70f5-4042-48a4-08d95b7e528f.png`),
          require(`@/assets/minis/zombiecleo-0da85596-0d5c-4439-48a5-08d95b7e528f.png`),
        ],
        vfTransitions: [ 'fade' ],
        vfCaptions: [
        ],
      }
    },
    methods: {
      invite: () => {
        window.open('http://mini.mrjoshuat.com');
      }
    }
  }
</script>
